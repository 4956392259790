import React, { useEffect, useState, useContext } from "react";
import { Col, Row, Form, Button, ButtonGroup, Breadcrumb, Table, Dropdown, InputGroup } from 'react-bootstrap';
import axios from "axios";
import { fetchAuthSession } from 'aws-amplify/auth';
import { ProductContext } from '../../ProductProvider';
import {Modal, ModalBody, ModalHeader, ModalFooter } from "react-bootstrap";
import Preloader from "../../components/Preloader/Preloader";
import EmailUpdateModal from "./EmailUpdateModal";
import {Helmet} from 'react-helmet-async';
import { useSnackbar } from 'notistack';

const config = require('../../config.json');
const validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
const phoneRegex=/^\+[1-9]{1}[0-9]{3,14}$/;
const dobRegex=/([12]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]))/;

export default () => {
  const { loggedUser, validateLogin } = useContext(ProductContext);
  const [searchResults, setSearchResults] = useState([]);
  const [loaded, setLoaded]=useState(true);
  const componentRef = React.useRef(null);
  const [updateEmailCollapsed, setUpdateEmailCollapsed]=useState(true);
  const [selectedEmail, setSelectedEmail]=useState('');
  const [selectedRegistrationNo, setSelectedRegistrationNo]=useState('');
  //const [registeredResults, setRegisteredResults]=useState([]);
  const [missingResults, setMissingResults]=useState([]);
  const [searchValue, setSearchValue]=useState("");
  const [searchTopic, setSearchTopic]=useState("EmailID");
  const { enqueueSnackbar } = useSnackbar();

  useEffect(()=>{
    if(!loggedUser.isAuthenticated){
      validateLogin();
    }
  },[loggedUser])


  const fetchCurrentYearDetails=async()=>{
   // event.preventDefault();
    try{
      if(searchValue===""){
        enqueueSnackbar("Search Value is empty Enter the search input and then hit Search button!",{variant : 'info'});
      }else{
        setLoaded(false);
        const { idToken } = (await fetchAuthSession()).tokens ?? {};
        await axios.get(`${config.api.invokeUrl}/subscribers/accounts/search?searchvalue=${searchValue}&searchOption=${searchTopic}`, {  
        headers: {
          Authorization: idToken,
          'x-api-key':config.api.key
        }
      }).then(async(response) => {
        //console.log("Response : ", response);
        if(response.data[0].length>0||response.data[1].length>0)
        {
       //     console.log("order status")
            //const tTempResponse=response.data;
         //   initializeValues(response.data);
           // var courseCompleted=0;
          /*  for(const i in tTempResponse){
                if(tTempResponse[i].assessmentResult==="PASS"){
                    //console.log("value : ", tTempResponse[i].registrationNo);
                    courseCompleted=courseCompleted+1;
                }
            }*/
              //const tProgressValue=((courseCompleted/tTempResponse.length)*100).toFixed(2);
              //setProgressValue(tProgressValue);
              
            setSearchResults(response.data[0]);
            //setRegisteredResults(response.data[1]);
            processResults(response.data[0], response.data[1]);
            enqueueSnackbar("Search completed Successfully!",{variant : 'success'});
        }else{
          enqueueSnackbar("No Records Found!",{variant : 'info'});
        }
        setLoaded(true);

    }).catch((error)=>{
      //console.log("error : ", error);
      enqueueSnackbar("Error Occured - " + error.message,{variant : 'warning'});
    setLoaded(true);
  })
}
    }catch(error){
      //console.log("error : ", error);
      enqueueSnackbar("Error Occured - " + error.message,{variant : 'warning'});
      setLoaded(true);
    }
  }

  const processResults=(taccounts,tregister)=>{
   // console.log("Register : ", tregister);
   // console.log("Users : ", taccounts);
    var ttaccounts=[];
    var tttaccounts=[];
    for (const i in taccounts){
      var tobject={};
      tobject.regNo=taccounts[i].Attributes[0]['Value'];
      ttaccounts.push(taccounts[i].Attributes[0]['Value']);
      tttaccounts.push(tobject);
    }
   // console.log("TTaccounts : ", tttaccounts);
   /* var absent = tregister.filter(object1 => {
      return !taccounts.some(object2 => {
        console.log("Reg : ", object2.Attributes[0]['Value']);
        return object1.registrationNo === object2.Attributes[0]['Value'];
      });
    });*/
    //var objectmissing = tregister.filter((item)=>{taccounts.includes(account=>{ item.registrationNo!=account.Attributes[0]['Value']})});
    //var absent1 = tregister.filter((item)=>{tttaccounts.includes(account=>{ item.registrationNo!=account})});

    const results = tregister.filter(({ registrationNo: id1 }) => !tttaccounts.some(( {regNo: id2 }) => id2 === id1));

    //console.log("missing values : ", results);
    setMissingResults(results);

  }

  /*const fetchApplicationDetails=async(event, searchValue)=>{
    event.preventDefault();
    fetchCurrentYearDetails(searchValue);
   /* try{
      if(batchYear===""){
        setAlertDetails({
          ...alertDetails,
          style:"warning",
          open:true,
          message: "Search Value is empty Enter the search input and then hit Search button!"
      });  

      }else{
        setLoaded(false);
        const searchTopic="Batch";
      await axios.get(`${config.api.invokeURL}/dashboard/search?fetchKey=${searchValue}&fetchOption=${searchTopic}`, {
        headers: {
       //   Authorization: access_token,
          'x-api-key':config.api.key
        }
      }).then(async(response) => {
      // console.log("response : ", response.data[0]);
        if(response.data.length>0)
        {
       //     console.log("order status")
            setSearchResults(response.data);
            setAlertDetails({
              ...alertDetails,
              style:"success",
              open:true,
              message: "Profile loaded Successfully!"
          });  

        }
        setLoaded(true);

    }).catch((error)=>{
      setAlertDetails({
        ...alertDetails,
        style:"warning",
        open:true,
        message:"Error Occured - " + error.message
    });        
    setLoaded(true);
  })
}
    }catch(error){
      setAlertDetails({
        ...alertDetails,
        style:"warning",
        open:true,
        message:"Error Occured - " + error.message
    });        
    setLoaded(true);
    }
  }*/

  const emailToggle = () => {
    //   console.log("clicked");
    setUpdateEmailCollapsed(!updateEmailCollapsed);
  }

  const handleEmailUpdateView =(event, item)=>{
    event.preventDefault();
    //console.log("item : ", item['Attributes'][1]['Value']);
   // setProfileDetails(item);
    setSelectedEmail(item['Attributes'][1]['Value']);
    setSelectedRegistrationNo(item['Attributes'][0]['Value']);
    setUpdateEmailCollapsed(!updateEmailCollapsed);
  }

  const handleCreateAccount=async(event, item)=>{
    //console.log("Item : ", item);
    event.preventDefault();
    try{
      var tphone="";
      if(item.areaCode!==""&& item.contactNo!==""){
      tphone=item.areaCode.toString()+item.contactNo.toString();
      }
      if(item.regDate===""){
        enqueueSnackbar("Date of Birth needs to be Updated first. Go to Student Profile and Update the Date of Birth",{variant : 'info'});
      }else if(!item.regDate.match(dobRegex)){
        enqueueSnackbar("Date of Birth needs to be Updated first. Go to Student Profile and Update the Date of Birth",{variant : 'info'});
      }else if(item.emailID===""){
        enqueueSnackbar("Email Address needs to be Updated first. Go to Student Profile and Update the Email Address",{variant : 'info'});
      }else if(!item.emailID.match(validRegex)){
        enqueueSnackbar("Email address is not in right format!",{variant : 'info'});
      }else if(!tphone.match(phoneRegex)){
        enqueueSnackbar("Area Code and Phone Number is not in right format!",{variant : 'info'});
      }else if(item.areaCode==="" || item.contactNo===""){
        enqueueSnackbar("Area Code and Mobile Number needs to be Updated first. Go to Student Profile and Update the Area Code and Mobile Number",{variant : 'info'});
      }else{
          setLoaded(false);
          const { idToken } = (await fetchAuthSession()).tokens ?? {};
          const { subscriberName, subscriberID, emailID, title, regDate, areaCode, contactNo, country } = item; 
          const params={
            subscriberID:subscriberID,
            emailID:emailID,
            subscriberName:subscriberName,
            title:title,
            regDate:regDate,
            areaCode:areaCode,
            contactNo:contactNo,
            address:country
          }
          
          //console.log("Params : ", params);
        //await axios.get(`${config.api.invokeURL}/dashboard/reports/fee-batchwise?fetchKey=${searchValue}&fetchOption=${searchTopic}`, {
          await axios.post(`${config.api.invokeUrl}/subscribers/accounts/createnew`,params, {  
          headers: {
            Authorization: idToken,
            'x-api-key':config.api.key
          }
        }).then(async(response) => {
         //console.log("Response : ", response);
         if(response.status===215){
          enqueueSnackbar(response.data,{variant : 'info'});
          }else if(response.status===202){
          await fetchCurrentYearDetails();
          enqueueSnackbar("Login Account Created Successfully!",{variant : 'success'});
          }
          setLoaded(true)
      }).catch((error)=>{
       // console.log("Error : ", error);
       enqueueSnackbar("Error Occured - " + error.message,{variant : 'warning'});
      setLoaded(true);
      window.scrollTo(0,0);
    })
  }
    }catch(error){
     // console.log("Error : ", error);
     enqueueSnackbar("Error Occured - " + error.message,{variant : 'warning'});
    setLoaded(true);
    window.scrollTo(0,0);
    }
    window.scrollTo(0,0);
  }

  const handleRecreateAccount=async(event, item)=>{
    event.preventDefault();
    try{
      
     /* const tphone=item.areaCode.toString()+item.contactNo.toString();
      if(item.dateOfBirth===""){
        setAlertDetails({
          ...alertDetails,
          style:"warning",
          open:true,
          message:"Date of Birth needs to be Updated first. Go to Student Profile and Update the Date of Birth"
      });        
      }else if(!item.dateOfBirth.match(dobRegex)){
        setAlertDetails({
          ...alertDetails,
          style:"warning",
          open:true,
          message:"Date of Birth needs to be Updated first. Go to Student Profile and Update the Date of Birth"
      });        
      }else if(item.emailID===""){
        setAlertDetails({
          ...alertDetails,
          style:"warning",
          open:true,
          message:"Email Address needs to be Updated first. Go to Student Profile and Update the Email Address"
      });        
      }else if(!item.emailID.match(validRegex)){
        setAlertDetails({
          ...alertDetails,
          style:"warning",
          open:true,
          message: "Email address is not in right format!"
      }); 
      }else if(!tphone.match(phoneRegex)){
        setAlertDetails({
          ...alertDetails,
          style:"warning",
          open:true,
          message: "Area Code and Phone Number is not in right format!"
      }); 
      }else if(item.gender===""){
        setAlertDetails({
          ...alertDetails,
          style:"warning",
          open:true,
          message:"Gender Status needs to be Updated first. Go to Student Profile and Update the Gender"
      });        
      }else if(item.areaCode==="" || item.mobileNumber===""){
        setAlertDetails({
          ...alertDetails,
          style:"warning",
          open:true,
          message:"Area Code and Mobile Number needs to be Updated first. Go to Student Profile and Update the Area Code and Mobile Number"
      });        
      }else*/{
          setLoaded(false);
          const { idToken } = (await fetchAuthSession()).tokens ?? {};
          const subscriberID=item.Attributes[0]['Value'];
          const params={
            subscriberID:subscriberID,
          }
         // console.log("Params : ", params);
        //await axios.get(`${config.api.invokeURL}/dashboard/reports/fee-batchwise?fetchKey=${searchValue}&fetchOption=${searchTopic}`, {
          await axios.post(`${config.api.invokeUrl}/subscribers/accounts/recreate`,params, {  
          headers: {
            Authorization: idToken,
            'x-api-key':config.api.key
          }
        }).then(async(response) => {
         //console.log("Response : ", response);
         if(response.status===202){
          await fetchCurrentYearDetails();
          enqueueSnackbar("Login Account Created Successfully!",{variant : 'success'});
          }else {
            enqueueSnackbar(response.data,{variant : 'success'});
          } 
          setLoaded(true)
      }).catch((error)=>{
        enqueueSnackbar("Error Occured - " + error.message,{variant : 'warning'});
      setLoaded(true);
      window.scrollTo(0,0);
    })
  }
    }catch(error){
     // console.log("Error : ", error);
     enqueueSnackbar("Error Occured - " + error.message,{variant : 'warning'});
    setLoaded(true);
    window.scrollTo(0,0);
    }
    window.scrollTo(0,0);
  }


return (
    <>
    <Helmet>
      <title>Subscriber's Login - Admin Office - VIDYAJYOTI JOURNAL OF THEOLOGICAL REFLECTION</title>
      <meta name="description" content="Subscriber's Login - Admin Office - VIDYAJYOTI JOURNAL OF THEOLOGICAL REFLECTION" />
      <meta name="keywords" content="Subscriber's Login - Admin Office - VIDYAJYOTI JOURNAL OF THEOLOGICAL REFLECTION" /> 
    </Helmet>

      <Preloader show={loaded ? false : true} />
     {/*Update Email*/}
     <div>
        <Modal onHide={emailToggle} show={!updateEmailCollapsed} size="lg">
                    <Modal.Header onHide={emailToggle} closeButton>
                    Update - Email Address
                    </Modal.Header>
                    <Modal.Body>    
                    <div className="text-center">
                      <EmailUpdateModal emailToggle={emailToggle} fetchCurrentYearDetails={fetchCurrentYearDetails} selectedEmail={selectedEmail} selectedRegistrationNo={selectedRegistrationNo}/>
                      </div>
                    </Modal.Body>
                    <Modal.Footer>
                    <Button onClick={emailToggle}>
                Close
            </Button>
            </Modal.Footer>
        </Modal>
        </div>

        {/*Payment History
     <div >
          <Modal toggle={historyToggle} isOpen={!historyCollapsed} style={{top:'10%'}} >
          <div >
             <ModalHeader toggle={historyToggle}>
                    </ModalHeader>
                    <ModalBody>
                      <HistoryView selectedItem={selectedItem} />
                    </ModalBody>
                    <ModalFooter>
            {' '}
            <Button onClick={historyToggle}>
                Close
            </Button>
            </ModalFooter>
          </div>
                   
        </Modal>
     
        </div>*/}

      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 mb-md-0">
          <Breadcrumb className="d-none d-md-inline-block" listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}>
            <Breadcrumb.Item><i className="fa fa-home" aria-hidden="true"></i></Breadcrumb.Item>
            <Breadcrumb.Item> Login</Breadcrumb.Item>
            <Breadcrumb.Item active> Accounts</Breadcrumb.Item>
          </Breadcrumb>
          <h4>Login Account Management</h4>
        </div>
        {/*<div className="btn-toolbar mb-2 mb-md-0">
          <ButtonGroup>
            
            <Button variant="outline-primary" size="sm">Export</Button>
          </ButtonGroup>
      </div>*/}
      </div>

      <div className="table-settings mb-4">
        <Row className="justify-content-start align-items-center">
        <Col md={4} className="mb-3">
              <Form.Group id="searchstud">               
                <Form.Select defaultValue="EmailID" value={searchTopic} onChange={(event)=>{setSearchTopic(event.target.value)}}>
                <option value="SubscriberID">Subscriber ID</option>
                <option value="SubscriberName">Subscriber Name</option>
                <option value="EmailID">Email</option>
                </Form.Select>
              </Form.Group>
            </Col>
          <Col  className="mb-3" md={8}>
            <InputGroup>
              <InputGroup.Text>
              <i className="fa fa-search" aria-hidden="true"></i>
              </InputGroup.Text>
            <Form.Control type="text" autoFocus placeholder="Search" value={searchValue} onChange={(event)=>{setSearchValue(event.target.value)}}/>
              <Button variant="primary" type="submit" onClick={(event)=>{fetchCurrentYearDetails()}}>Submit</Button>
            </InputGroup>
          </Col>
        </Row>
      </div>

{missingResults.length>0?<><h3>{missingResults.length} - Records found without Login accounts</h3>
<Table ref={componentRef} hover responsive>
             <thead>
                <tr>
                <th>#</th>
                <th>Subscriber ID</th>
                <th>Name</th>
                <th>Email</th>  
                <th>Mobile</th>  
                <th>Status</th>   
                <th>Action</th>
                </tr>
            </thead>
            <tbody>
            {missingResults.map((item, index)=>

            <tr key={index}>
            <th scope="row">{index+1}</th>
            <td>{item.subscriberID}</td>
            <td>{item.subscriberName}</td>
            <td> {item.emailID}</td>
            <td>{item.areaCode}{item.contactNo}</td>
            <td>{item.subStatus}</td>
            <td> 
            <Dropdown as={ButtonGroup}>
            <Dropdown.Toggle as={Button} split variant="link" className="text-dark m-0 p-0">
              <span className="icon icon-sm">
              <i className="fa fa-ellipsis-h" aria-hidden="true"></i>
              </span>
            </Dropdown.Toggle>
            <Dropdown.Menu>
             <Dropdown.Item onClick={(event)=>{handleCreateAccount(event,item)}} >
             <i className="fa fa-plus" aria-hidden="true"></i> Create Account
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
            </td>
            </tr>)}
            </tbody>
            </Table></>:null}

      {searchResults.length>0 ?<><h3>{missingResults.length} - Records found WITH Login accounts</h3>
      <Table ref={componentRef} hover responsive>
             <thead>
                <tr>
                <th>#</th>
                <th>Subscriber ID</th>
                <th>Username</th>
                <th>Account Status</th>  
                <th>Login Status</th>  
                <th>Action</th>
                </tr>
            </thead>
            <tbody>
            {searchResults.length>0 && searchResults.map((item, index)=>
            <tr key={index}>
            <th scope="row">
                {index+1}
            </th>
            <td>
                {item.Attributes[0]['Value']}
            </td>
            <td>
               {item.Attributes[1]['Value']}
            </td>
            <td> 
              {item.Enabled?"Enabled":"Disabled"}
            </td>
            <td> 
              {item.UserStatus}
            </td>
            <td> 
            <Dropdown as={ButtonGroup}>
            <Dropdown.Toggle as={Button} split variant="link" className="text-dark m-0 p-0">
              <span className="icon icon-sm">
              <i className="fa fa-ellipsis-h" aria-hidden="true"></i>
              </span>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item onClick={(event)=>{handleEmailUpdateView(event,item)}} >
              <i className="fa fa-envelope" aria-hidden="true"></i> Change Email Address
              </Dropdown.Item>
              <Dropdown.Item onClick={(event)=>{handleRecreateAccount(event,item)}} >
              <i className="fa fa-exchange" aria-hidden="true"></i> Re-Create Account
              </Dropdown.Item>

            </Dropdown.Menu>
          </Dropdown>
            </td>
            </tr>)}
            </tbody>
            </Table></>:null}
    </>
  );
};
